import {
    DEFAULT_IMAGE_LIST
} from "../constants/DefaultImages.constants";
import {
    FORWARD_SLASH,
    HTTPS,
    EQUALS,
    AMPERSAND,
    QUESTION_MARK,
    EMAIL_DELIMITER
} from "../constants/Misc.constants";
import {
    REGION_PAGE_TYPES,
    REGION_PAGE_VIEW_ALL_PARAMS
} from "../constants/RegionPage.constants";
import {
    REAL_TIME_COVERAGE_REGION_OPTIONS
} from "../constants/RealTimeCoverage.constants";
import {
    LINK_PUBLICATION_PAGE
} from '../constants/LinkPaths.constants';
import EnvironmentService from "./Environment.service";
import moment from "moment";
import { randomNumber } from "../utils/valueUtils";
import DOMPurify from "dompurify";
import React, { Component } from 'react';
import {
    SEARCH_RESULTS_SHOW_COWEN_DATA, SEARCH_RESULTS_SHOW_HISTORIC_DATA,
    SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES
} from "../constants/SearchResults.constants";

export default class UtilsService extends Component {

    static spliceFirst(_array, max) {
        if (!_array) {
            var _tempArray = [];
            return _tempArray;
        }
        if (_array.length > max) {
            return _array.splice(max - 1, _array.length - max - 1);
        } else {
            return _array;
        }
    }

    static getProductId(products, labels) {
        if (!products) {
            return null;
        }
        var idList = [];
        for (var i = 0; i < products.length; i++) {
            for (var j = 0; j < labels.length; j++) {
                if (products[i].label === labels[j]) {
                    idList.push(products[i].value);
                }
            }
        }
        return idList;
    }

    static getRandomDefaultImage = () => {
        return DEFAULT_IMAGE_LIST[Math.floor(randomNumber() * DEFAULT_IMAGE_LIST.length)];
    }

    static isHawkish = (text) => {
        var str = text + "";
        const regexp = /hawkish/gi;
        str = str.toLowerCase();
        if (str.match(regexp)) {
            return true;
        }
        return false;
    }

    static isDovish = (text) => {
        var str = text + "";
        const regexp = /dovish/gi;
        str = str.toLowerCase();
        if (str.match(regexp)) {
            return true;
        }
        return false;
    }

    static formatNumber(number, decimal) {
        const _number = parseFloat(number);
        return _number.toFixed(decimal);
    }

    static isValueNumeric(currentValue, key) {
        if (key === ".") {
            return UtilsService.isNumeric(currentValue + key + "0");
        } else {
            return UtilsService.isNumeric(currentValue + key);
        }
    }

    static isNumeric(value) {
        var RE = /^-{0,1}\d*\.{0,1}\d+$/;
        return (RE.test(value));
    }

    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static buildShareLink(firmName, contentId) {
        if (firmName && contentId) {
            return HTTPS + EnvironmentService.hostname + LINK_PUBLICATION_PAGE + firmName + FORWARD_SLASH + contentId;
        } else {
            return "";
        }
    }

    static addSpace(text, totalSpaceCount) {
        var newText = text + "";
        if (text.length < totalSpaceCount) {
            var spacesToAdd = totalSpaceCount - text.length;
            for (var i = 0; i <= spacesToAdd; i++) {
                newText += ' ';
            }
        }
        return newText;
    }

    static buildURLParams(params) {
        const paramKeys = Object.keys(params);
        var urlParamString = '';
        var first = true;
        for (var i = 0; i < paramKeys.length; i++) {
            if (params[paramKeys[i]]) {
                if (first) {
                    urlParamString += QUESTION_MARK + paramKeys[i] + EQUALS + params[paramKeys[i]];
                    first = false;
                } else {
                    urlParamString += AMPERSAND + paramKeys[i] + EQUALS + params[paramKeys[i]];
                }
            }
        }
        return urlParamString;
    }

    static getRegionParams(type) {
        switch (type) {
            case REGION_PAGE_TYPES.UNITED_STATES: return REGION_PAGE_VIEW_ALL_PARAMS.UNITED_STATES;
            case REGION_PAGE_TYPES.CANADA: return REGION_PAGE_VIEW_ALL_PARAMS.CANADA;
            case REGION_PAGE_TYPES.ASIA_PACIFIC: return REGION_PAGE_VIEW_ALL_PARAMS.ASIA_PACIFIC;
            case REGION_PAGE_TYPES.JAPAN: return REGION_PAGE_VIEW_ALL_PARAMS.JAPAN;
            case REGION_PAGE_TYPES.NEW_ZEALAND: return REGION_PAGE_VIEW_ALL_PARAMS.NEW_ZEALAND;
            case REGION_PAGE_TYPES.AUSTRALIA: return REGION_PAGE_VIEW_ALL_PARAMS.AUSTRALIA;
            case REGION_PAGE_TYPES.EUROPE: return REGION_PAGE_VIEW_ALL_PARAMS.EUROPE;
            case REGION_PAGE_TYPES.EUROZONE: return REGION_PAGE_VIEW_ALL_PARAMS.EUROZONE;
            case REGION_PAGE_TYPES.UNITED_KINGDOM: return REGION_PAGE_VIEW_ALL_PARAMS.UNITED_KINGDOM;
            case REGION_PAGE_TYPES.SCANDIES: return REGION_PAGE_VIEW_ALL_PARAMS.SCANDIES;
            case REGION_PAGE_TYPES.EMEA: return REGION_PAGE_VIEW_ALL_PARAMS.EMEA;
            case REGION_PAGE_TYPES.ASIA: return REGION_PAGE_VIEW_ALL_PARAMS.ASIA;
            case REGION_PAGE_TYPES.CHINA: return REGION_PAGE_VIEW_ALL_PARAMS.CHINA;
            case REGION_PAGE_TYPES.ASIA_EX_CHINA: return REGION_PAGE_VIEW_ALL_PARAMS.ASIA_EX_CHINA;
            case REGION_PAGE_TYPES.LATIN_AMERICA: return REGION_PAGE_VIEW_ALL_PARAMS.LATIN_AMERICA;
            default: return null;
        }
    }

    static getHomePageRegionByValue(value) {
        for (let i = 0; i < REAL_TIME_COVERAGE_REGION_OPTIONS.length; i++) {
            if (REAL_TIME_COVERAGE_REGION_OPTIONS[i].value === value) {
                return REAL_TIME_COVERAGE_REGION_OPTIONS[i];
            }
        }
    }

    static isNullorEmpty(values) { // values is an array of alphanumeric
        for (let val of values) {
            if (val && val !== '') {
                return false;
            }
        }
        return true;
    }


    static buildEQShareText(pdfLink, riskText, recommendText, targetPrice, mktCap, date, description, isForCowen, bluematrixXmlAbbr) {
        var eqShareText = "";
        eqShareText += "Report Date: " + moment.utc(date).format("ddd MMM DD, YYYY") + EMAIL_DELIMITER + EMAIL_DELIMITER;
        if (description) {
            if (description.length > 800) {
                eqShareText += encodeURIComponent(description.substring(0, 800)) + "..." + EMAIL_DELIMITER;
            } else {
                eqShareText += encodeURIComponent(description) + EMAIL_DELIMITER;
            }
        }
        if (recommendText && SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES.includes(bluematrixXmlAbbr)) {
            eqShareText += "Recommendation: " + recommendText + EMAIL_DELIMITER;
        }
        if (riskText) {
            eqShareText += "Risk: " + riskText + EMAIL_DELIMITER;
        }
        if ( targetPrice && SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES.includes(bluematrixXmlAbbr) ) {
            eqShareText += "12-Month Target Price: " + targetPrice + EMAIL_DELIMITER;
        } else if (targetPrice) {
            eqShareText += "";
        }
        if (mktCap && SEARCH_RESULTS_SHOW_COWEN_DATA.includes(bluematrixXmlAbbr)) {
            eqShareText += "Mkt Cap: " + mktCap + EMAIL_DELIMITER;
        } else if (mktCap && SEARCH_RESULTS_SHOW_HISTORIC_DATA.includes(bluematrixXmlAbbr)) {
            eqShareText += "Mkt Cap (f.d.) ($mm): " + mktCap + EMAIL_DELIMITER;
        } else if (mktCap && !SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES.includes(bluematrixXmlAbbr)) {
            eqShareText += "";
        }
        eqShareText += pdfLink + EMAIL_DELIMITER + EMAIL_DELIMITER + EMAIL_DELIMITER + EMAIL_DELIMITER;
        eqShareText += "TD Securities Equity Research Website: https://portal.tdsecurities.com/alpha/landing" + EMAIL_DELIMITER;
        eqShareText += "Contact Us:  EquityResearchWebHelp@tdsecurities.com" + EMAIL_DELIMITER;
        if (isForCowen) {
            eqShareText += "Important Disclosures:  https://tdcowen.bluematrix.com/sellside/Disclosures.action";
        } else {
            eqShareText += "Important Disclosures:  https://portal.tdsecurities.com/alpha/important-disclosures";
        }



        return eqShareText;
    }

    static sanitizeInnerHtml = (data) => {
        return { __html: DOMPurify.sanitize(data) }
    }

    static executeInnerHtml = (data) => {
        return <div className="inner-html" dangerouslySetInnerHTML={this.sanitizeInnerHtml(data)} />
    }
}

const executeInnerHtml = UtilsService.executeInnerHtml;
const sanitizeInnerHtml = UtilsService.sanitizeInnerHtml;
export {
    executeInnerHtml,
    sanitizeInnerHtml
};
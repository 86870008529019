import React from 'react';
import ModalComponent from '../misc/modal/Modal.component';
import SiteIndexComponent from './SiteIndex.component';
import ContactUsComponent from './contact-us/ContactUs.component';

export default class FooterComponent extends React.Component {
    siteIndexModal = React.createRef();
    contactUsModal = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            showTopButton: false
        };
        this.handleTopClick = this.handleTopClick.bind(this);
        this.hideSiteIndexModal = this.hideSiteIndexModal.bind(this);
        this.showSiteIndexModal = this.showSiteIndexModal.bind(this);
        this.showContactUsModal = this.showContactUsModal.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleTopClick() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.setState(
            {
                showTopButton: false
            }
        );
    }
    showSiteIndexModal() {
        this.siteIndexModal.current.showModal();
    }
    hideSiteIndexModal() {
        this.siteIndexModal.current.hideModal();
    }
    showContactUsModal() {
        this.contactUsModal.current.showModal();
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(event) {
        let footer = document.getElementsByClassName('tds-footer-divider')
        let footerTopOffset = footer[0].offsetTop
        if (window.scrollY <= footerTopOffset - window.innerHeight
             && this.state.showTopButton === true) {
            this.setState({showTopButton: false});
        }
        else if (window.scrollY >= footerTopOffset - window.innerHeight
                 && this.state.showTopButton !== true) {
            this.setState({showTopButton: true});
        }
    }
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="tds-footer-divider"></div>
                    <div className="tds-container">
                        <div className="tds-footer-contact-us">
                            <span onClick={this.showContactUsModal}>
                                    <span className="tds-footer-contact-us-text" title="Contact Us">Contact Us</span>
                                    <span className="td-icon td-icon-rightCaret icon-small"></span>
                            </span>
                        </div>
                        <div className="tds-footer-links">
                            <div className="main">
                                <div className="link">
                                    <a href="https://www.tdsecurities.com/ca/en/privacy-security"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Privacy & Security"
                                        className="tds-footer-link">
                                        Privacy & Security
                                    </a>
                                </div>
                                <div className="link">
                                    <a href="https://www.tdsecurities.com/ca/en/legal"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Legal"
                                        className="tds-footer-link">
                                        Legal
                                    </a>
                                </div>
                                <div className="link">
                                    <a href="https://www.tdcanadatrust.com/customer-service/accessibility/accessibility-at-td/index.jsp"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Accessibility"
                                        className="tds-footer-link">
                                        Accessibility
                                    </a>
                                </div>
                                <div className="link">
                                    <span
                                        className="tds-footer-link"
                                        title="Site Index"
                                        onClick={this.showSiteIndexModal}
                                    >
                                        Site Index
                                    </span>
                                </div>
                                <div className="link">
                                    <a href="https://www.tdsecurities.com/ca/en/global-disclaimer"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Global Disclaimer"
                                        className="tds-footer-link">
                                        Global Disclaimer
                                    </a>
                                </div>
                                <div className="link">
                                    <a
                                        className="tds-footer-link"
                                        tabIndex={0}
                                        href="/#"
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            if (window.OneTrust) {
                                                window.OneTrust.ToggleInfoDisplay();
                                            }
                                        }}
                                    >
                                        Manage online experience
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tds-footer-logos">
                            <a href="https://www.ciro.ca/"
                               title="Logo of Canadian Investment Regulatory Organization"
                               target="_blank"
                               rel="noopener noreferrer"
                                nofollow={'true'}>
                                <img src="/assets/img/CIRO-Logo-Dark-Desktop-EN.png"
                                    alt="Logo of Canadian Investment Regulatory Organization" />
                            </a>
                            <a href="https://www.cipf.ca/"
                                title="Logo of Canadian Investor Protection Fund"
                                target="_blank"
                                rel="noopener noreferrer"
                                nofollow={'true'}>
                                <img src="/assets/img/CIPF-Logo-Colour-EN.png"
                                    className="td-rte-margin-top-none"
                                    alt="Logo of Canadian Investor Protection Fund" />
                            </a>
                        </div>
                        <div className="tds-footer-note">
                            TD Securities (USA) LLC is a member of <a href="https://www.sipc.org/" target="_blank" rel="noopener noreferrer" nofollow={'true'} title="SIPC">SIPC</a> and <a
                                href="https://www.finra.org/" target="_blank" rel="noopener noreferrer" nofollow={'true'} title="FINRA">FINRA</a> (see FINRA <a
                                    href="https://brokercheck.finra.org/" target="_blank" rel="noopener noreferrer" nofollow={'true'} title="BrokerCheck">BrokerCheck</a>).
                        </div>
                        {
                            this.state.showTopButton &&
                            <div className="tds-footer-top fixed" title="Top" onClick={this.handleTopClick}>
                                <span className="td-icon td-icon-upCaret icon-regular"></span>
                                <span className="tds-footer-top-text">Top</span>
                            </div>
                        }
                    </div>
                </footer>
                <ModalComponent
                    ref={this.siteIndexModal}
                    id={'siteIndexModal'}
                    className={'tds-site-index'}
                >
                    <SiteIndexComponent hideSiteIndexModal={this.hideSiteIndexModal} />
                </ModalComponent>
                <ModalComponent
                    ref={this.contactUsModal}
                    id={'contactUsModal'}
                    className={'tds-contact-us'}
                >
                   <ContactUsComponent />
                </ModalComponent>
            </React.Fragment>
        )
    }
}

import React from "react";
import { Link } from "react-router-dom";
import ModalComponent from "../../misc/modal/Modal.component";
import ContactUsComponent from "../../footer/contact-us/ContactUs.component";
import {
  LINK_PATH_SALES_LITERATURE_PAGE,
  LINK_PATH_FICC_RESEARCH_CREDIT_STRATEGY_PAGE,
} from "../../../constants/LinkPaths.constants";
import { COWEN_DISCLOSURE_LINK } from "../../../constants/Misc.constants";

const LandingPageFooterComponent = (props) => {

  const contactUsModal = React.createRef();
  const showSiteIndexModal = () => {
    contactUsModal.current.showModal();
  };

  return (
    <footer className="tds-landing-cta-section tds-landing-footer">
      <div
        className="tds-landing-footer-contact-us"
        onClick={showSiteIndexModal}
      >
        <span title="Contact Us">
          <span className="tds-footer-contact-us-text">Contact Us</span>
          <span className="tds-landing-footer-contact-us-caret td-icon td-icon-rightCaret icon-small"></span>
        </span>
      </div>
      <div className="tds-landing-footer-links">
        <ul>
          <li>
            <a
              title="Privacy & Security"
              href="https://www.tdsecurities.com/ca/en/privacy-security"
              target="_blank"
              className="tds-landing-footer-link"
              rel="noopener noreferrer"
            >
              Privacy & Security
            </a>
          </li>
          <li>
            <a
              title="Legal"
              href="https://www.tdsecurities.com/ca/en/legal"
              target="_blank"
              className="tds-landing-footer-link"
              rel="noopener noreferrer"
            >
              Legal
            </a>
          </li>
          <li>
            <a
              title="Accessibility"
              href="https://www.tdcanadatrust.com/customer-service/accessibility/accessibility-at-td/index.jsp"
              targe="_blank"
              className="tds-landing-footer-link"
            >
              Accessibility
            </a>
          </li>
          <li>
            <a
              href={COWEN_DISCLOSURE_LINK}
              targe="_blank"
              title="Important Disclosures"
              className="tds-landing-footer-link"
            >
              Important Disclosures
            </a>
          </li>
          <li>
            <Link
              to={LINK_PATH_SALES_LITERATURE_PAGE}
              title="Sales Literature Disclaimer"
              className="tds-landing-footer-link"
            >
              <span
                title="Sales Literature Disclaimer"
                className="tds-landing-footer-link"
              >
                Sales Literature Disclaimer
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={LINK_PATH_FICC_RESEARCH_CREDIT_STRATEGY_PAGE}
              title="FICC Research and Credit Strategy"
              className="tds-landing-footer-link"
            >
              <span
                title="FICC Research and Credit Strategy"
                className="tds-landing-footer-link"
              >
                FICC Research and Credit Strategy
              </span>
            </Link>
          </li>
          <li>
            <a
              title="Global Disclaimer"
              href="https://www.tdsecurities.com/ca/en/global-disclaimer"
              targe="_blank"
              className="tds-landing-footer-link"
            >
              Global Disclaimer
            </a>
          </li>
          <li>
            <a
                className="tds-landing-footer-link"
                tabIndex={0}
                href="/#"
                onMouseDown={(e) => {
                  e.preventDefault();
                  if (window.OneTrust) {
                    window.OneTrust.ToggleInfoDisplay();
                  }
                }}
            >
              Manage online experience
            </a>
          </li>
        </ul>
      </div>
      <div className="tds-landing-footer-logos">
        <a
            id="iiroc-footer-logo"
            href="https://www.ciro.ca/"
            title="Logo of Canadian Investment Regulatory Organization"
            target="_blank"
            nofollow={"true"}
            rel="noopener noreferrer"
        >
          <img
              src="/assets/img/CIRO-Logo-Dark-Desktop-EN.png"
              alt="Logo of Canadian Investment Regulatory Organization"
          />
        </a>
        <a
          id="cipf-footer-logo"
          href="https://www.cipf.ca/"
          title="Logo of Canadian Investor Protection Fund"
          target="_blank"
          nofollow={"true"}
          rel="noopener noreferrer"
        >
          <img
            src="/assets/img/CIPF-Logo-Colour-EN.png"
            className="td-rte-margin-top-none"
            alt="Logo of Canadian Investor Protection Fund"
          />
        </a>
      </div>
      <div className="tds-landing-footer-note">
        TD Securities (USA) LLC is a member of{" "}
        <a title="SIPC" href="https://www.sipc.org/" rel="nofollow">
          SIPC
        </a>{" "}
        and{" "}
        <a title="FINRA" href="https://www.finra.org/" rel="nofollow">
          FINRA
        </a>{" "}
        (see FINRA{" "}
        <a
          title="BrokerCheck"
          href="https://brokercheck.finra.org/"
          rel="nofollow"
        >
          BrokerCheck
        </a>
        ).
      </div>
      <ModalComponent
        ref={contactUsModal}
        id={"contactUsModal"}
        className={"tds-contact-us"}
      >
        <ContactUsComponent />
      </ModalComponent>
    </footer>
  );
};

export default LandingPageFooterComponent;
